import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import Slider from "../components/swiper"
import TileSection from "../components/tileSection"
import AchievementsSection from "../components/achievementsSection"
import IconSection from "../components/iconSection"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

import "./index.css"

const IndexPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <section id="home-landing" className="page-section">
        <AnimationWrapper>
          <Slider data={data.page.frontmatter.slides} />
        </AnimationWrapper>
      </section>

      <section id="homepage-tiles" className="page-section">
        <Container>
          <AnimationWrapper>
            <TileSection data={data.page.frontmatter.tiles_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium  homepage-desc" data={data.page.frontmatter.text_section} />
          </AnimationWrapper>
        </Container>
      }

      {/* {data.page.frontmatter.text_sections[1] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[1]} />
          </AnimationWrapper>
        </Container>
      } */}

      <section id="transpol-achievements">
        <Container>
          <AnimationWrapper>
            <AchievementsSection data={data.page.frontmatter.achievements_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      {/* <section id="partnerzy-grid-section" className="page-section">
        <ImageGridSection location="partnerzy" data={data.page.frontmatter.partnerzy_grid}/>
      </section> */}

      <section id="partnerzy-carousel-section" className="page-section">
        <Container>
          <AnimationWrapper className="description-medium">
            <Carousel data={data.page.frontmatter.partnerzy_carousel} location="partnerzy"/>
          </AnimationWrapper>
        </Container>
      </section>

      <section id="homepage-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icon_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="klienci-carousel-section" className="page-section">
        <Container>
          <AnimationWrapper className="description-medium">
            <Carousel data={data.page.frontmatter.klienci_carousel} location="klienci"/>
          </AnimationWrapper>
        </Container>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "index" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slides{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_url
          button_style
          text
        }
        tiles_section{
          text_section{
            title
            title_visible
            align
            subtitle
            # icon{
            #   childImageSharp {
            #     gatsbyImageData(
            #       transformOptions: {fit: COVER},
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            button_text
            button_url
            button_style
            text
          }
          tiles{
            title
            type
            active
            url
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
            description
          }
        }
        text_section{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
        }
        achievements_section{
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          achievements{
            value
            postfix
            description
          }
        }
        icon_section{
          type
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        partnerzy_carousel{
          text_section{
            title
            title_visible
            # align
            subtitle
            button_text
            button_url
            button_style
            text
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
              id
            }
            description
            status
          }
        }
        klienci_carousel{
          text_section{
            title
            title_visible
            align
            subtitle
            button_text
            button_url
            button_style
            text
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
              id
            }
            description
            status
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
  }
`

export default IndexPage
