import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import TextSection from "./textSection";

import "./achievementsSection.css";

const AchievementsSection = ({ data, location }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Adjust this threshold as needed
  });

  const animateCounter = () => {
    const counterElements = document.querySelectorAll(".achievement-value");

    counterElements.forEach((counterElement) => {
      const counter = counterElement.querySelector("span.num");
      const countTo = parseInt(counter.getAttribute("data-to"), 10);
      const during = parseInt(counter.getAttribute("data-time"), 10);

      let startTime;
      const updateCounter = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const progress = Math.min((currentTime - startTime) / during, 1);
        const currentCount = Math.ceil(progress * countTo);

        counter.textContent = currentCount.toFixed(0);

        if (progress < 1) {
          requestAnimationFrame(updateCounter);
        }
      };

      // Check if the counter element is in the viewport
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          requestAnimationFrame(updateCounter);
          observer.disconnect();
        }
      }, { threshold: 0.2 }); // Adjust this threshold as needed

      observer.observe(counterElement);
    });
  };

  if (inView && !triggerAnimation) {
    // setTimeout(() => {
      setTriggerAnimation(true);
      animateCounter();
    // }, 1000);
  }

  return (
    <div className={`${location}-achievements`}>
      <div className="achievements-grid-container page-section">
        {data.image && (
          <div className="image-container">
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
        )}
        <div ref={ref} className={`achievements ${data.type} ${data.image ? "" : "no-image"}`}>
          <div className="text-section-container">
            {data.text_section.title_visible && (
              <TextSection className="description-medium" data={data.text_section} />
            )}
          </div>
          <div className={`achievements-grid ${data.type}`}>
            {data.achievements.map((achievement, key) => (
              <div key={key} className="achievement-wrapper">
                <h3 className="achievement-value">
                  <span className="num" data-to={achievement.value} data-time="2000">
                    {triggerAnimation ? achievement.value : 0}
                  </span>
                  {achievement.postfix &&
                    <span className="achievement-postfix">{achievement.postfix}</span>
                  }
                </h3>
                <p className="achievement-desc">{achievement.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AchievementsSection